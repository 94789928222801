import React, { useEffect } from 'react'
import { navigate } from 'gatsby'
import Helmet from 'react-helmet'
import PageLayout from '../../components/layouts/PageLayout'

export default () => {
    useEffect(() => {
        navigate('/power-to-protect', { replace: true })
    })

    return (
        <PageLayout className="guideline-list ptp-root">
            <Helmet title="Power to protect" />
        </PageLayout>
    )
}
